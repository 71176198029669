import { mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Accueil',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Public',
    action: 'read',

    /*
    children: [
      {
        title: 'Auteurs',
        to: 'dashboard-crm',
        resource: 'Public',
        action: 'read',
      },
      {
        title: 'Publications',
        to: 'dashboard-analytics',
      },
    ], */
  },
]
