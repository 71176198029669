import {
  mdiMapMarkerOutline,
  mdiAccountOutline,
  mdiBookAccountOutline,
  mdiBookMultipleOutline,
  mdiMap,
  mdiAccountCashOutline,
  mdiDraw,
  mdiFormatText,
  mdiNotebookOutline,
  mdiPoliceBadgeOutline,
  mdiBookOpenPageVariantOutline,
  mdiChartLine,
  mdiAccessPointNetwork,
  mdiBookOpenOutline,
  mdiRhombusSplit,
  mdiFormatPageBreak,
  mdiBook,
} from '@mdi/js'

export default [
  {
    subheader: 'GESTIONS',
  },
  {
    title: 'Manuscrits',
    icon: mdiBookAccountOutline,
    to: 'manuscripts',
    resource: 'Manuscript',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Auteurs',
    icon: mdiAccountOutline,
    to: 'authors',
    resource: 'Author',
    action: 'read',

    /* badge: '3',
    badgeColor: 'warning', */
  },
  {
    title: 'Auteurs Google Ads',
    icon: mdiAccountOutline,
    to: 'ads_authors',
    resource: 'Author',
    action: 'read',

  },
  {
    title: 'Adresses',
    icon: mdiMapMarkerOutline,
    to: 'address',
    resource: 'Address',
    action: 'read',

    /* badge: '3',
    badgeColor: 'warning', */
  },
  {
    title: 'Librairies',
    icon: mdiMapMarkerOutline,
    to: 'libraries',
    resource: 'Library',
    action: 'read',

    /* badge: '3',
    badgeColor: 'warning', */
  },
  {
    title: 'Cartographie',
    icon: mdiMap,
    to: 'cartographies',
    resource: 'Cartographie',
    action: 'read',
  },
  {
    title: 'Publications',
    icon: mdiBookMultipleOutline,
    to: 'publications',
    resource: 'Publication',
    action: 'read',

    /* badge: '3',
    badgeColor: 'warning', */
  },
  {
    title: 'Signatures',
    icon: mdiDraw,
    to: 'signatures',
    resource: 'Signature',
    action: 'read',
  },
  {
    title: 'Collecte des éléments',
    icon: mdiRhombusSplit,
    to: 'collecte',
    resource: 'Collect',
    action: 'read',
  },
  {
    title: 'Traitement texte',
    icon: mdiFormatText,
    to: 'assistance_texte',
    resource: 'Text',
    action: 'read',
  },
  {
    title: 'Traitement couverture',
    icon: mdiNotebookOutline,
    to: 'assistance_couverture',
    resource: 'Cover',
    action: 'read',
  },
  {
    title: 'Supervision générale',
    icon: mdiPoliceBadgeOutline,
    to: 'supervision',
    resource: 'Supervision',
    action: 'read',
  },
  {
    title: 'Parutions',
    icon: mdiBookOpenPageVariantOutline,
    to: 'assistance_publication',
    resource: 'Publication',
    action: 'assistant',
  },
  {
    title: 'Communication',
    icon: mdiAccessPointNetwork,
    to: 'communications',
    resource: 'Communication',
    action: 'read',
  },
  {
    title: 'Paiements',
    icon: mdiAccountCashOutline,
    to: 'payments',
    resource: 'Paiment',
    action: 'read',
  },
  {
    title: 'Résiliations',
    icon: mdiFormatPageBreak,
    to: 'resiliations',
    resource: 'Resiliation',
    action: 'read',
  },

  {
    title: 'Reporting',
    icon: mdiChartLine,
    to: 'report',
    resource: 'Report',
    action: 'read',
  },
  {
    title: 'ISBN Numeriques',
    icon: mdiBookOpenOutline,
    to: 'isbns',
    resource: 'isbns',
    action: 'read',
  },

  {
    title: 'Commandes',
    icon: mdiBook,
    to: 'orders',
    resource: 'Order',
    action: 'read',
  },
]
