import axios from '@axios'

export default {
  getParticularCases(page, field) {
    return axios.post(`particular_case/list?page=${page}`, field)
  },

  getParticularCase(id) {
    return axios.get(`particular_case/${id}`)
  },

  addParticularCase(particularCase) {
    return axios.post(`particular_case`, particularCase)
  },

  deleteParticularCase(id) {
    return axios.delete(`particular_case/${id}`)
  },

  updateParticularCase(particularCase) {
    return axios.put(`particular_case/${particularCase.id}`, particularCase)
  },
}
