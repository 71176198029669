import particularCaseService from "@/store/services/particularCaseService";

const state = {
  field: {
    label: null,
    paginate: 10,
  },
  label_field: null,
  paginate_field: null,
  metaParticularCase: null,
  particularCase: null,
}

const getters = {
  particular_cases: state => (state.metaParticularCase ? state.metaParticularCase.data : []),
  current_page: state => (state.metaParticularCase ? state.metaParticularCase.current_page : 0),
  last_page: state => (state.metaParticularCase ? state.metaParticularCase.last_page : 0),
  per_page: state => (state.metaParticularCase ? state.metaParticularCase.per_page : 0),
  total: state => (state.metaParticularCase ? state.metaParticularCase.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getParticularCasesList({ commit, state }, { page, field }) {
    if (state.metaParticularCase && state.metaParticularCase.current_page === page
      && state.label_field === field.label && state.paginate_field === field.paginate) return state.metaParticularCase

    return particularCaseService.getParticularCases(page, field).then(({ data }) => {
      commit('SET_META_PARTICULAR_CASE', data)
      commit('SET_LABEL_FIELD', field.label)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getParticularCaseById({ commit, state }, id) {
    return particularCaseService.getParticularCase(id).then(({ data }) => {
      commit('SET_PARTICULAR_CASE', data.result)

      return data
    })
  },

  updateParticularCase({ commit, state }, particularCase) {
    return particularCaseService.updateParticularCase(particularCase).then(({ data }) => {
      commit('UPDATE_PARTICULAR_CASE', { particularCase: data, item: particularCase })

      return data
    })
  },

  addParticularCase({ commit, state }, particularCase) {
    return particularCaseService.addParticularCase(particularCase).then(({ data }) => {
      commit('ADD_PARTICULAR_CASE', data )

      return data
    })
  },

  deleteParticularCase({ commit, state }, particularCase) {
    return particularCaseService.deleteParticularCase(particularCase.id).then(({ data }) => {
      commit('REMOVE_PARTICULAR_CASE', data )

      return data
    })
  }
}

const mutations = {
  ADD_PARTICULAR_CASE(state, particularCase) {
    state.metaParticularCase.data.unshift(particularCase)
  },

  REMOVE_PARTICULAR_CASE(state, item) {
    if (state.metaParticularCase) { state.metaParticularCase.data.splice(state.metaParticularCase.data.findIndex(t => t.id === item.id), 1) }
  },
  UPDATE_PARTICULAR_CASE(state, { particularCase, item }) {
    if (state.metaParticularCase) { state.metaParticularCase.data.splice(state.metaParticularCase.data.findIndex(t => t.id === item.id), 1, particularCase) }
  },
  // eslint-disable-next-line no-shadow
  SET_PARTICULAR_CASE(state, item) {
    state.operator = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_LABEL_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_PARTICULAR_CASE(state, metaParticularCase) {
    state.metaParticularCase = metaParticularCase
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
