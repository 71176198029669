import appAndPages from './app-and-pages'
import configurations from './configurations'
import gestion from './gestion'
import chartsAndMaps from './charts-and-maps'
import dashboard from './dashboard'
import formsAndTables from './forms-and-tables'
import others from './others'
import uiElements from './ui-elements'

// Array of sections
export default [...dashboard, ...gestion, ...configurations,  /*...appAndPages, ...uiElements, ...formsAndTables, ...chartsAndMaps, ...others*/]
